import React from "react";
import { Admin, fetchUtils, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { AdminResources } from "./utils/canopie-api";
import { Auth } from "aws-amplify";
import { Options } from "ra-core";
import { EditDataField } from "./components/EditDataField";
import { ResourceType } from "./types";
import { ListComponentFromResource } from "./components/ListComponentFromResource";
import { ShowComponentFromResource } from "./components/ShowComponentFromResource";
import { CreateComponentFromResource } from "./components/CreateComponentFromResource";
import { EditComponentFromResource } from "./components/EditComponentFromResource";
import { EditDashboardWidget } from "./components/EditDashboardWidget";
import { ListDataFields } from "./components/ListDataFIelds";

const App = () => {
  const [resources, setResources] = React.useState<ResourceType[]>([]);

  React.useEffect(() => {
    AdminResources.all().then(setResources);
  }, []);

  if (!resources.length) {
    return null;
  }

  const httpClient = async (
    url: any,
    options?: Options,
  ): Promise<{
    status: number;
    headers: Headers;
    body: string;
    json: any;
  }> => {
    const urlObject = new URL(url);

    const filter = JSON.parse(urlObject.searchParams.get("filter") || "null");
    if (filter && Object.values(filter).length) {
      urlObject.searchParams.set("query", JSON.stringify(filter));
    }
    const range = JSON.parse(urlObject.searchParams.get("range") || "null");
    if (range && range.length === 2) {
      const [start, end] = range;
      urlObject.searchParams.set("limit", `${end + 1 - start}`);
      urlObject.searchParams.set("offset", `${start}`);
    }
    const sort = JSON.parse(urlObject.searchParams.get("sort") || "null");
    if (sort && sort.length === 2) {
      const [orderBy, orderDirection] = sort;
      urlObject.searchParams.set("orderBy", orderBy);
      urlObject.searchParams.set("orderDirection", orderDirection);
    }

    urlObject.searchParams.delete("filter");
    urlObject.searchParams.delete("range");
    urlObject.searchParams.delete("sort");

    url = urlObject.toString();
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    // add your own headers here
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    // @ts-ignore
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = simpleRestProvider(
    `${process.env.REACT_APP_API_DOMAIN}/admin` || "",
    httpClient,
    "count",
  );

  const dataFieldResource = resources.find(
    (resource) => resource.name === "Data Field",
  );
  const dashboardViewResource = resources.find(
    (resource) => resource.name === "Dashboard View Widget",
  );

  return (
    <Admin dataProvider={dataProvider}>
      {dataFieldResource && (
        <Resource
          options={{ label: "Manage Data Fields" }}
          name="manage-data-fields"
          show={ShowComponentFromResource(dataFieldResource)}
          list={ListDataFields(dataFieldResource)}
          create={CreateComponentFromResource(dataFieldResource)}
          edit={EditDataField}
        />
      )}
      {dashboardViewResource && (
        <Resource
          options={{ label: "Manage Dashboard Widgets" }}
          name="manage-dashboard-widgets"
          show={ShowComponentFromResource(dashboardViewResource)}
          list={ListComponentFromResource(dashboardViewResource)}
          create={CreateComponentFromResource(dashboardViewResource)}
          edit={EditDashboardWidget}
        />
      )}
      {resources.map((resource) => (
        <Resource
          key={resource.path}
          options={{ label: resource.pluralized_name }}
          name={resource.path}
          show={ShowComponentFromResource(resource)}
          list={ListComponentFromResource(resource)}
          create={CreateComponentFromResource(resource)}
          edit={EditComponentFromResource(resource)}
        />
      ))}
    </Admin>
  );
};

export default App;
