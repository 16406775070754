import {Column, Relationship, ResourceType} from "../types";
import {BooleanField, DateField, EmailField, NumberField, ReferenceField, TextField} from "react-admin";
import React from "react";
import ColorField from "./ColorField";


export const displayFieldForColumn = (column: Column, resource: ResourceType) => {
  const commonProps = {
    source: column.name,
  };

  if (column.name.includes('color')) {
    return <ColorField {...commonProps} />;
  }

  switch (column.type) {
    case "boolean":
      return <BooleanField {...commonProps}/>;
    case "text":
    case "string[]":
    case "string":
      if (column.name === 'email') {
        return <EmailField {...commonProps} source="email"/>;
      }

      return <TextField {...commonProps}/>;
    case "integer":
      return <NumberField {...commonProps}/>;
    case "datetime":
      return <DateField {...commonProps}/>;
    case "relation":
      let relationship: Relationship | undefined = undefined;

      for (let i = 0; i < resource.relationships.length; i++) {
        const relation = resource.relationships[i];

        if (relation.column === column.name) {
          relationship = relation;
          break;
        }
      }

      if (!relationship) {
        return null;
      }

      return (
        <ReferenceField {...commonProps} reference={relationship.plural_name}>
          <TextField source={relationship.display_column}/>
        </ReferenceField>
      );
    default:
      return null;
  }
};