import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import AuthenticatedApp from './AuthenticatedApp';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';

// Configure Amplify
Amplify.configure({
  // TODO: Make these load from env variables
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  },
});

ReactDOM.render(
  <AuthenticatedApp/>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
