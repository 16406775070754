import { ResourceType } from "../types";
import { Datagrid, List, TextInput, useRecordContext } from "react-admin";
import { UserBulkActionButtons } from "./UserBulkActionButtons";
import { displayFieldForColumn } from "./displayFieldForColumn";
import React from "react";

const UsedByDisplay = () => {
  const record = useRecordContext();

  return (
    <>
      <ul>
        {record?.used_by?.map((item: string) => <li key={item}>{item}</li>)}
      </ul>
    </>
  );
};

const postFilters = [
  <TextInput label="Name" source="name" defaultValue="" alwaysOn />,
];

export const ListDataFields = (resource: ResourceType) => {
  const Component = () => (
    <List filters={postFilters}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          resource.name === "User" ? <UserBulkActionButtons /> : undefined
        }
      >
        <UsedByDisplay />
        {resource.columns.map((column) =>
          displayFieldForColumn(column, resource),
        )}
      </Datagrid>
    </List>
  );

  Component.displayName = `${resource.pluralized_name.replaceAll(" ", "")}List`;

  return Component;
};
