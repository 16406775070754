import React from 'react';
import {useRecordContext} from 'react-admin';

export const ColorDisplay = ({ color }: { color: string }) => (
  <div style={{minWidth: '100px'}}>
    <div style={{ backgroundColor: color, width: '14px', height: '14px', borderRadius: '4px', float: 'left', border: '1px solid black', marginTop: '3px', marginRight: '4px' }}/>
    <div>{color}</div>
  </div>
);

const ColorField = ({ source }: { source: string }) => {
  const record = useRecordContext();

  const color = record && record[source];
  if (!color) {
    return null;
  }

  // render a small box withe the color and the hex code next to it
  return <ColorDisplay color={color} />;
};

export default ColorField;