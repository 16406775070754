import SyncUserData from "./SyncUserData";
import {BulkDeleteButton} from "react-admin";
import React from "react";

export const UserBulkActionButtons = () => (
  <>
    <SyncUserData/>
    {/* default bulk delete action */}
    <BulkDeleteButton/>
  </>
);