import {Column, DISABLED_COLUMNS, Relationship, ResourceType} from "../types";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import React from "react";
import {ColorPicker} from "./ColorPicker";

export const inputFieldForColumn = (column: Column, resource: ResourceType) => {
  const commonProps: { source: string, fullWidth: boolean, disabled?: boolean, key: string } = {
    source: column.name,
    fullWidth: true,
    key: column.name,
  };

  if (DISABLED_COLUMNS.includes(column.name)) {
    commonProps.disabled = true;
  }

  if (column.name.includes('color')) {
    return <ColorPicker {...commonProps} />;
  }

  switch (column.type) {
    case "boolean":
      return <BooleanInput {...commonProps}/>;
    case "text":
      return <TextInput {...commonProps} multiline/>;
    case "string":
      return <TextInput {...commonProps}/>;
    case "string[]":
      return <ArrayInput source={column.name}>
        <SimpleFormIterator>
          {/* // @ts-ignore */}
          <TextInput source=""/>
        </SimpleFormIterator>
      </ArrayInput>;
    case "integer":
      return <NumberInput {...commonProps}/>;
    case "datetime":
      return <DateInput {...commonProps}/>;
    case "relation":
      let relationship: Relationship | undefined = undefined;

      for (let i = 0; i < resource.relationships.length; i++) {
        const relation = resource.relationships[i];

        if (relation.column === column.name) {
          relationship = relation;
          break;
        }
      }

      if (!relationship) {
        return null;
      }

      let additionalProps: any = {};

      const partnerOnlyResources = [
        'Reporting User',
        'Dashboard View',
        'Raw Data View',
      ];

      if (column.name === 'insurance_provider_id' && partnerOnlyResources.includes(resource.name)) {
        additionalProps.filter = {partner: true};
      }

      return (
        <ReferenceInput
          {...commonProps}
          reference={relationship.plural_name}
          enableGetChoices={({q}) => true}
          {...additionalProps}
          sort={{field: relationship.display_column, order: 'ASC'}}
          perPage={100000}
          fullWidth
        >
          <AutocompleteInput optionText={relationship.display_column} fullWidth/>
        </ReferenceInput>
      );
    default:
      return null;
  }
};