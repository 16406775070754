import {ResourceType} from "../types";
import {Edit, SimpleForm} from "react-admin";
import {inputFieldForColumn} from "./inputFieldForColumn";
import React from "react";

export const EditComponentFromResource = (resource: ResourceType) => {
  const Component = () => (
    <Edit>
      <SimpleForm>
        {resource.columns.map((column) => inputFieldForColumn(column, resource))}
      </SimpleForm>
    </Edit>
  );

  Component.displayName = `${resource.pluralized_name.replaceAll(' ', '')}Edit`;

  return Component;
};