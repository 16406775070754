import {ResourceType} from "../types";
import {Show, SimpleShowLayout} from "react-admin";
import {displayFieldForColumn} from "./displayFieldForColumn";
import React from "react";

export const ShowComponentFromResource = (resource: ResourceType) => {
  const Component = () => (
    <Show>
      <SimpleShowLayout>
        {resource.columns.map((column) => displayFieldForColumn(column, resource))}
      </SimpleShowLayout>
    </Show>
  );

  Component.displayName = `${resource.pluralized_name.replaceAll(' ', '')}Show`;

  return Component;
}