import React from 'react';
import {Authenticator} from '@aws-amplify/ui-react';
import {Auth, Hub} from 'aws-amplify';
import App from "./App";
import '@aws-amplify/ui-react/styles.css';

enum CognitoGroup {
  ADMIN = 'admin'
}

// aws-amplify defined type is incorrect, so making our own
type CognitoUser = {
  signInUserSession: {
    accessToken: {
      payload: {
        'cognito:groups'?: CognitoGroup[]
      }
    }
  }
}

function adminLoggedIn(user: CognitoUser | null): boolean {
  // admin users have their own cognito pool now, so successfully logging in means it is an admin user
  return !!user;
}

function AuthenticatedApp() {
  const [user, updateUser] = React.useState<CognitoUser | null>(null);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => updateUser(user))
      .catch(() => console.log('No signed in user.'));

    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          return updateUser(data.payload.data);
        case 'signOut':
          return updateUser(null);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    if (!adminLoggedIn(user)) {
      Auth.signOut();
    }
  }, [user]);

  if (adminLoggedIn(user)) {
    console.log(user);
    return <App/>;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Authenticator loginMechanisms={["email"]} hideSignUp/>
    </div>
  );
}

export default AuthenticatedApp;