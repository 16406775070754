import {ResourceType} from "../types";
import {Datagrid, List} from "react-admin";
import {UserBulkActionButtons} from "./UserBulkActionButtons";
import {displayFieldForColumn} from "./displayFieldForColumn";
import React from "react";

export const ListComponentFromResource = (resource: ResourceType) => {
  const Component = () => (
    <List>
      <Datagrid rowClick="edit" bulkActionButtons={resource.name === 'User' ? <UserBulkActionButtons/> : undefined}>
        {resource.columns.map((column) => displayFieldForColumn(column, resource))}
      </Datagrid>
    </List>
  );

  Component.displayName = `${resource.pluralized_name.replaceAll(' ', '')}List`;

  return Component;
};