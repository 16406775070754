export type Column = {
  name: string;
  type: 'string' | 'integer' | 'datetime' | 'boolean' | 'relation' | 'text' | 'string[]';
}
export type Relationship = {
  type: 'belongs_to' | 'has_many';
  name: string;
  column: string;
  plural_name: string;
  display_column: string;
}
export type ResourceType = {
  name: string;
  pluralized_name: string;
  path: string;
  columns: Column[];
  relationships: Relationship[];
}
export const DISABLED_COLUMNS = ['id', 'created_at', 'updated_at', 'cognito_id', 'last_api_call_at', 'last_api_call_ip'];